import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import aboutImage from '../../../assets/images/about-img.png';

const About = () => {
  const { t } = useTranslation();
  return (
    <div className=' bg-blue-stone-200 flex justify-center'>
      <div className='flex flex-col md:flex-row gap-7 w-[900px] py-[50px] px-[20px] md:py-[100px]'>
        <div className='w-full flex justify-center'>
          <img className='md:w-full w-[60%]' src={aboutImage} alt='/' />
        </div>
        <div>
          <h3>{t('about.aboutUs')}</h3>
          <h2>{t('about.whoWeAre')}</h2>
          <p>
            {t('about.description1')}
            <br />
            {t('about.description2')}
            <br />
            {t('about.description3')}
          </p>
          <Link to='/contact'>
            <button>{t('about.getInTouch')}</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default About;
