import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { Link } from "react-router-dom";
import NavMenu from "./NavMenu";
import SvgIcon from "../common/SvgIcon/SvgIcon";

const Footer = () => {
  const { t } = useTranslation();

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <>
      <div className="flex justify-center bg-blue-stone-500 text-white">
        <div className="w-section grid grid-cols-1 md:auto-cols-auto md:grid-flow-col py-[40px] px-[20px] md:py-[100px] md:grid-cols-3 gap-8">
          <div className="w-full md:col-span-2">
            <Link to="/">
              <h2 className="color-white text-xl md:text-2xl">
                TODOENUNAREFORMA
              </h2>
            </Link>
            <p className="md:w-[75%]">{t("footer.description")}</p>
          </div>
          <div className="w-full">
            <h2 className="text-xl border-b-[1px] w-fit">
              {t("footer.menuLinks")}
            </h2>
            <NavMenu
              flexCol={"flex-col"}
              itemClass={"px-0 py-2 hover:text-white"}
            />
          </div>
          <div>
            <h2 className="text-xl border-b-[1px] w-fit">
              {t("footer.contact")}
            </h2>
            <ul>
              <li className="font-extralight px-0 py-2 hover:text-white">
                {t("contact.address")}
              </li>
              <li className="font-extralight px-0 py-2 hover:text-white">
                {t("contact.phone")}
              </li>
              <li className="font-extralight px-0 py-2 hover:text-white">
                <a href="https://wa.me/+34602558926">{t("contact.whatsapp")}</a>
              </li>
              <li className="font-extralight px-0 py-2 hover:text-white">
                {t("contact.email")}
              </li>
            </ul>
            <div className="flex flex-row gap-4 cursor-pointer">
              <div onClick={() => handleLanguageChange("es")}>
                <SvgIcon src="spain.svg" width="30px" height="30px" />
              </div>
              <div onClick={() => handleLanguageChange("en")}>
                <SvgIcon src="uk.svg" width="30px" height="30px" />
              </div>
              <div onClick={() => handleLanguageChange("ro")}>
                <SvgIcon src="romania.svg" width="30px" height="30px" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center bg-blue-stone-200">
        <div className="w-section flex flex-col md:flex-row py-6 px-[20px]">
          <div className="flex flex-row w-full gap-4 text-[16px] flex-wrap font-light items-center">
            <span>TODOENUNAREFORMA.COM © {new Date().getFullYear()}</span>
          </div>
          <div className="w-full flex md:justify-end">
            <span className="text-[16px] font-light">
              Rafael Construcciónes
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
