import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Formik } from 'formik';
import * as Yup from 'yup';

import { basePrice } from './quoteData';

const DisplayFormikState = (props) => (
  <div style={{ margin: '1rem 0' }}>
    <pre
      style={{
        background: '#f6f8fa',
        fontSize: '.65rem',
        padding: '.5rem',
      }}
    >
      <strong>props</strong> = {JSON.stringify(props, null, 2)}
    </pre>
  </div>
);

const Quote = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [quote, setQuote] = useState('');

  const inputStyles =
    'mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0';

  const buttonStyles =
    ' text-white bg-blue-stone py-2 px-10 font-extralight uppercase text-lg mt-4 w-full xsm:w-auto';

  const initialValues = {
    name: '',
    location: '',
    email: '',
    phone: '',
    kitchen: '',
    bathroom: '',
    livingroom: '',
    bedroom: '',
    hall: '',
    interior: '',
    exterior: '',
    message: '',
  };

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          padding: '0 20px',
          textAlign: 'center',
        }}
      >
        <div className='text-black text-2xl'>{message}</div>
        <div className='loadingSpinnerContainer'>
          <div className='loadingSpinner'></div>
        </div>
      </div>
    );
  }

  if (quote) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <div className='flex flex-col justify-center px-5 text-center'>
          <h2>{`${t('quote.yourQuoteIs')} ${quote}`}</h2>
          <button onClick={() => setQuote('')}>{t('quote.submitQuote')}</button>
          <button onClick={() => setQuote('')}>
            {t('quote.calculateAgain')}
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className='flex justify-center'>
      <div className='w-section flex flex-col py-[50px] md:py-[100px] px-[20px] gap-8'>
        <h2>{t('quote.getFreeQuote')}</h2>

        <div>
          <h3 className='mb-3'>{t('quote.aboutYourProject')}</h3>
          <div>
            <Formik
              initialValues={initialValues}
              onSubmit={async (values) => {
                let totalQuote;

                if (!values.interior) {
                  totalQuote =
                    values.bathroom * basePrice.bathroom +
                    values.bedroom * basePrice.bedroom +
                    values.kitchen * basePrice.kitchen +
                    values.hall * basePrice.hall +
                    values.livingroom * basePrice.livingroom;
                } else {
                  totalQuote = values.interior * basePrice.interior;
                }

                if (values.exterior) {
                  totalQuote =
                    totalQuote + values.exterior * basePrice.exterior;
                }

                if (!totalQuote) {
                  alert(t('quote.fillDimensions'));
                  return;
                }

                const calculate = async () => {
                  window.scrollTo(0, 0);
                  setLoading(true);
                  setMessage(t('quote.checkingNumbers'));
                  await delay(2000);
                  log1();
                  await delay(3000);
                  log2();
                  await delay(4000);
                  log3();
                  await delay(3000);
                  log4();
                };

                calculate();

                async function delay(duration) {
                  return new Promise((resolve) => {
                    setTimeout(resolve, duration);
                  });
                }

                function log1() {
                  setMessage(t('quote.submittingRequest'));
                }

                function log2() {
                  setMessage(t('quote.contactingSuppliers'));
                }

                function log3() {
                  setMessage(t('quote.gettingOffer'));
                }
                function log4() {
                  setLoading(false);

                  setQuote(
                    ` £${totalQuote}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
                      '.00'
                  );
                }
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email(t('quote.errors.validEmail'))
                  .required(t('quote.errors.required')),
                phone: Yup.string().required(t('quote.errors.required')),
              })}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  dirty,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                } = props;
                return (
                  <form
                    className='grid grid-cols-1 gap-6'
                    onSubmit={handleSubmit}
                  >
                    <label className='block'>
                      <span className='text-gray-700'>
                        {t('quote.fullName')}
                      </span>
                      <input
                        type='text'
                        placeholder={t('quote.yourName')}
                        className={inputStyles}
                        name='name'
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span className='text-red-500'>{errors.name}</span>
                    </label>

                    <label className='block'>
                      <span className='text-gray-700'>
                        {t('quote.whereProject')}
                      </span>
                      <input
                        type='text'
                        placeholder={t('quote.projectLocation')}
                        className={inputStyles}
                        name='location'
                        value={values.location}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span className='text-red-500'>{errors.location}</span>
                    </label>
                    <label className='block'>
                      <span className='text-gray-700'>
                        {t('quote.yourEmail') + '*'}
                      </span>
                      <input
                        type='email'
                        placeholder={t('quote.yourEmail')}
                        className={inputStyles}
                        name='email'
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span className='text-red-500'>{errors.email}</span>
                    </label>
                    <label className='block'>
                      <span className='text-gray-700'>
                        {t('quote.yourPhone')}
                      </span>
                      <input
                        type='tel'
                        placeholder={t('quote.yourPhone')}
                        className={inputStyles}
                        name='phone'
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span className='text-red-500'>{errors.phone}</span>
                    </label>
                    <label className='block'>
                      <span className='text-gray-700'>
                        {t('quote.yourKitchen')}
                      </span>
                      <input
                        type='number'
                        placeholder={t('quote.totalKitchen')}
                        className={inputStyles}
                        name='kitchen'
                        value={values.kitchen}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span className='text-red-500'>{errors.kitchen}</span>
                    </label>
                    <label className='block'>
                      <span className='text-gray-700'>
                        {t('quote.yourBathroom')}
                      </span>
                      <input
                        type='number'
                        placeholder={t('quote.totalBathroom')}
                        className={inputStyles}
                        name='bathroom'
                        value={values.bathroom}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span className='text-red-500'>{errors.bathroom}</span>
                    </label>
                    <label className='block'>
                      <span className='text-gray-700'>
                        {t('quote.yourBedrooms')}
                      </span>
                      <input
                        type='number'
                        placeholder={t('quote.totalBedrooms')}
                        className={inputStyles}
                        name='bedroom'
                        value={values.bedroom}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span className='text-red-500'>{errors.bedroom}</span>
                    </label>
                    <label className='block'>
                      <span className='text-gray-700'>
                        {t('quote.yourLivingroom')}
                      </span>
                      <input
                        type='number'
                        placeholder={t('quote.totalLivingroom')}
                        className={inputStyles}
                        name='livingroom'
                        value={values.livingroom}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span className='text-red-500'>{errors.livingroom}</span>
                    </label>
                    <label className='block'>
                      <span className='text-gray-700'>
                        {t('quote.yourHalls')}
                      </span>
                      <input
                        type='number'
                        placeholder={t('quote.totalHalls')}
                        className={inputStyles}
                        name='hall'
                        value={values.hall}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span className='text-red-500'>{errors.hall}</span>
                    </label>
                    <label className='block'>
                      <span className='text-gray-700'>
                        {t('quote.completeInterior')}
                      </span>
                      <input
                        type='number'
                        placeholder={t('quote.totalInterior')}
                        className={inputStyles}
                        name='interior'
                        value={values.interior}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span className='text-red-500'>{errors.interior}</span>
                    </label>
                    <label className='block'>
                      <span className='text-gray-700'>
                        {t('quote.completeExterior')}
                      </span>
                      <input
                        type='number'
                        placeholder={t('quote.totalExterior')}
                        className={inputStyles}
                        name='exterior'
                        value={values.exterior}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span className='text-red-500'>{errors.exterior}</span>
                    </label>

                    <textarea
                      className={inputStyles}
                      placeholder={t('quote.otherDetails')}
                      name='message'
                      value={values.message}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {/* <button
                      className={buttonStyles}
                      type='button'
                      onClick={() => {
                        handleReset();
                        setLoading(false);
                      }}
                      disabled={!dirty || isSubmitting}
                    >
                      Reset
                    </button> */}
                    <button
                      className={buttonStyles}
                      type='submit'
                      disabled={isSubmitting}
                    >
                      {t('quote.submitForm')}
                    </button>

                    {/* <DisplayFormikState {...props} /> */}
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quote;
