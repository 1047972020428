import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const NavMenu = (props) => {
  const { t } = useTranslation();

  return (
    <nav>
      <ul className={`flex ${props.flexCol}`}>
        <li className={props.itemClass}>
          <NavLink className={props.navActive} to="/">
            {t("navMenu.home")}
          </NavLink>
        </li>
        <li className={props.itemClass}>
          <NavLink className={props.navActive} to="/services">
            {t("navMenu.services")}
          </NavLink>
        </li>
        <li className={props.itemClass}>
          <NavLink className={props.navActive} to="/about">
            {t("navMenu.about")}
          </NavLink>
        </li>
        <li className={props.itemClass}>
          <NavLink className={props.navActive} to="/contact">
            {t("navMenu.contact")}
          </NavLink>
        </li>
        <li className={props.itemClass}>
          <NavLink className={props.navActive} to="/gdpr">
            {t("navMenu.gdpr")}
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default NavMenu;
