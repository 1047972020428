import React from "react";

const Gdpr = () => {
  return (
    <div className="py-[50px] px-[20px]">
      <h2>
        <strong>The GDPR</strong>{" "}
        <span>(General Data Protection Regulation)</span>
      </h2>
      <p>
        The 'General Data Protection Regulation' (GDPR) is a piece of European
        data protection legislation designed to replace and strengthen the '1995
        EU Data Protection Directive' as well as unify these standards across
        the European Union.
      </p>
      <p>
        The regulation aims to give consumers greater control over their
        personal data. It does this by increasing fines for companies that do
        not take the security of their customers data seriously, up to 20
        million euros or 4% of turnover, as well as increasing consumers rights
        to access the data companies hold on them.
      </p>
      <p>
        The GDPR became law on 25th May, 2018 and applies to any company who
        stores or processes the personal data of an EU citizen.
      </p>
      <h3>How this affects you?</h3>
      <p>
        When you use our service, we store your personal data on our servers.
        Doing so allows us to operate our website, issue you documentation for
        your journey and ensure your travel runs smoothly. This classes us, Rado
        Chauffeurs Ltd, as a 'data controller' and a 'data processor', you as a
        customer or passenger of Rafael Construcciónes are a 'data subject',
        although you may also be acting as a 'data controller' especially if you
        are booking on behalf of someone else. As a data controller, you may
        need to take steps yourself in order to comply with GDPR requirements.
      </p>
      <h3>Your Responsibilities</h3>
      <p>
        As a data controller, you should ensure you're compliant with the GDPR.
        We recommend you contact a legal professional to find out how the GDPR
        legislation will affect your organisation. The ICO recommends taking
        these 12 steps.
      </p>
      <ol>
        <li>
          <strong>Awareness</strong>: You should make sure that decision makers
          and key people in your organisation are aware that the law is changing
          to the GDPR. They need to appreciate the impact this is likely to
          have.
        </li>
        <li>
          <strong>Information you hold</strong>: You should document what
          personal data you hold, where it came from and who you share it with.
          You may need to organise an information audit.
        </li>
        <li>
          <strong>Communicating privacy information</strong>: You should review
          your current privacy notices and put a plan in place for making any
          necessary changes in time for GDPR implementation.
        </li>
        <li>
          <strong>Individuals' rights</strong>: You should check your procedures
          to ensure they cover all the rights individuals have, including how
          you would delete personal data or provide data electronically and in a
          commonly used format.
        </li>
        <li>
          <strong>Subject access requests</strong>: You should update your
          procedures and plan how you will handle requests within the new
          timescales and provide any additional information.
        </li>
        <li>
          <strong>Lawful basis for processing personal data</strong>: You should
          identify the lawful basis for your processing activity in the GDPR,
          document it and update your privacy notice to explain it.
        </li>
        <li>
          <strong>Consent</strong>: You should review how you seek, record and
          manage consent and whether you need to make any changes. Refresh
          existing consents now if they don't meet the GDPR standard.
        </li>
        <li>
          <strong>Children</strong>: You should start thinking now about whether
          you need to put systems in place to verify individuals' ages and to
          obtain parental or guardian consent for any data processing activity.
        </li>
        <li>
          <strong>Data breaches</strong>: You should make sure you have the
          right procedures in place to detect, report and investigate a personal
          data breach.
        </li>
        <li>
          <strong>
            Data Protection by Design and Data Protection Impact Assessments
          </strong>
          : You should familiarise yourself now with the ICO's code of practice
          on Privacy Impact Assessments as well as the latest guidance from the
          Article 29 Working Party, and work out how and when to implement them
          in your organisation.
        </li>
        <li>
          <strong>Data Protection Officers</strong>: You should designate
          someone to take responsibility for data protection compliance and
          assess where this role will sit within your organisation's structure
          and governance arrangements. You should consider whether you are
          required to formally designate a Data Protection Officer.
        </li>
        <li>
          <strong>International</strong>: If your organisation operates in more
          than one EU member state (ie you carry out cross-border processing),
          you should determine your lead data protection supervisory authority.
          Article 29 Working Party guidelines will help you do this.
        </li>
      </ol>
      <h3>What We're Doing to be GDPR Compliant</h3>
      <p>
        Rafael Construcciónes takes data security seriously. We take many steps
        to protect your data, these include:
      </p>
      <ul>
        <li>Enforcing 'HTTPS' connections to our web servers.</li>
        <li>Running regular security scans on our network.</li>
        <li>
          Regular scheduled scans of all PC's with heavy duty virus protection
          software.
        </li>
        <li>
          Keeping an inventory of all the personal data we store and ensuring we
          only collect data that is required to carry out the service.
        </li>
        <li>
          Maintaining a 'Data Flow Map' which lists where we store our data
          including any third parties that are involved.
        </li>
        <li>
          Regularly reviewing our Data Protection Policies and ensuring
          appropriate training is provided to employees.
        </li>
        <li>
          Training staff on the 'Data Breach Protocol' to ensure everyone knows
          what to do in the unlikely event of a data breach.
        </li>
      </ul>
      <p>
        We have a handful of documents available for customers which should
        enhance your understanding of how we use your data.
      </p>
      <p>
        The GDPR has expanded consumers right of access to their data, as well
        as the removal/deletion of records. There are however some legal
        limitations placed on us that could limit our ability to comply with
        your request. These include our licensing authority's requirement that
        we store 1 year of full journey records or our obligation as a limited
        company to store accounting (transaction) records for 6 years from the
        end of the last company financial year they relate to.{" "}
      </p>
      <p>
        Rafael Construcciónes is committed to being fully compliant with this
        regulation.
      </p>
      <p>
        If you have any questions you can download a PDF copy of the General
        Data Protection Regulation{" "}
        <a href="http://ec.europa.eu/justice/data-protection/reform/files/regulation_oj_en.pdf">
          here
        </a>
        .
      </p>
    </div>
  );
};

export default Gdpr;
