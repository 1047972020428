import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import AboutPage from "./pages/About";
import ContactPage from "./pages/Contact";
import QuotePage from "./pages/Quote";
import HomePage from "./pages/HomePage";
import ServicesPage from "./pages/Services";
import Gdpr from "./pages/Gdpr/Gdpr";

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />}></Route>
        <Route path="/services" element={<ServicesPage />}></Route>
        <Route path="/about" element={<AboutPage />}></Route>
        <Route path="/contact" element={<ContactPage />}></Route>
        <Route path="/quote" element={<QuotePage />}></Route>
        <Route path="/gdpr" element={<Gdpr />}></Route>
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
