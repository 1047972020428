import emailjs from '@emailjs/browser';

import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ResultMessage = () => {
  const { t } = useTranslation();
  return <p className='text-green-500 text-xl'>{t('contact.messageSent')}</p>;
};

const ErrorMessage = () => {
  const { t } = useTranslation();
  return <p className='text-red-500 text-xl'>{t('contact.error')}</p>;
};

const ContactForm = (props) => {
  const { t } = useTranslation();
  const form = useRef();
  const [result, setResult] = useState(false);
  const [error, setError] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setError(false);

    emailjs
      .sendForm(
        'service_lox5ulb',
        'template_gqcaz0k',
        form.current,
        'quZxVYDf5l_8nrY7L'
      )
      .then(
        (result) => {
          setError(false);
          setResult(true);
          e.target.reset();
        },
        (error) => {
          setError(true);
          console.log(error);
        }
      );
  };

  return (
    <form ref={form} onSubmit={sendEmail} className='flex flex-col gap-3'>
      <input name='fullName' type='text' placeholder={t('contact.fullName')} />
      <input
        name='email'
        type='email'
        placeholder={t('contact.emailAddress')}
      />
      <input name='phone' type='tel' placeholder={t('contact.phoneNumber')} />
      <input
        name='subject'
        type='text'
        placeholder={t('contact.serviceSubject')}
      />
      <textarea name='message' type='text' placeholder={t('contact.messaj')} />
      <div
        data-size='compact'
        class='g-recaptcha'
        data-sitekey='6LfU5JYhAAAAADveBuZfNiNG9bLx_IZVMEIQVnVY'
      ></div>
      <button>{t('contact.sendMessage')}</button>
      <div>{result ? <ResultMessage /> : null}</div>
      <div>{error ? <ErrorMessage /> : null}</div>
    </form>
  );
};

export default ContactForm;
