import { useTranslation } from 'react-i18next';
import ContactForm from '../../../components/ContactForm/ContactForm';

const Contact = () => {
  const { t } = useTranslation();
  return (
    <div className='flex justify-center'>
      <div className='w-section flex flex-col md:flex-row-reverse py-[50px] md:py-[100px] px-[20px] gap-8'>
        <div className='w-full'>
          <div className='bg-blue-stone-100 p-5'>
            <h3>{t('contact.contact')}</h3>
            <h2>{t('contact.getFreeQuote')}</h2>
            <ContactForm />
          </div>
        </div>
        <div className='w-full'>
          <h3>{t('contact.contactInfo')}</h3>
          <h2>{t('contact.getInTouch')}</h2>
          <p className='md:w-[80%]'>{t('contact.getInTouchDescription')}</p>
          <hr className='w-40 bg-blue-stone-500 h-[2px] my-6' />
          <ul className='m-0'>
            <li className='py-2 px-0'>{t('contact.address')}</li>
            <li className='py-2 px-0'>{t('contact.phone')}</li>
            <li className='py-2 px-0'>
              <a href='https://wa.me/+34602558926'>{t('contact.whatsapp')}</a>
            </li>
            <li className='py-2 px-0'>{t('contact.email')}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Contact;
